export const APP_DATA_KEYS = {
    // Redirected to Stage
    MODULE_REDIRECTED: "MODULE_REDIRECTED",
    PROGRAM_TYPE: "PROGRAM_TYPE",
    STAGING_DATA: "STAGING_DATA",
    HOT_SWAP_IN_PROGRESS: 'HOT_SWAP_IN_PROGRESS',
    ATTACH_EMPTY_CART_REDIRECTED_FLOW: 'ATTACH_EMPTY_CART_REDIRECTED_FLOW',
    CURRENT_CART_INFO: "CURRENT_CART_INFO",
    CART_LIST: "CART_LIST"
};

class AppData {
    save(key, value) {
        if (value === null || value === undefined) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    remove(key) {
        sessionStorage.removeItem(key);
    }

    get(key) {
        const value = sessionStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            console.log(`error while parsing data for ${key} with value ${value}`);
            return null;
        }
    }

    wasModuleRedirected() {
        return this.get(APP_DATA_KEYS.MODULE_REDIRECTED);
    }

    redirectComplete() {
        this.save(APP_DATA_KEYS.MODULE_REDIRECTED, false);
    }

    redirectModule() {
        this.save(APP_DATA_KEYS.MODULE_REDIRECTED, true);
    }

    saveProgramType(scope) {
        this.save(APP_DATA_KEYS.PROGRAM_TYPE, scope)
    }

    getProgramType() {
        return this.get(APP_DATA_KEYS.PROGRAM_TYPE)
    }

    startHotSwap() {
        this.save(APP_DATA_KEYS.HOT_SWAP_IN_PROGRESS, true);
    }

    isHotSwapInProgress() {
        return this.get(APP_DATA_KEYS.HOT_SWAP_IN_PROGRESS);
    }

    completeHotSwap() {
        this.save(APP_DATA_KEYS.HOT_SWAP_IN_PROGRESS, false);
    }

    saveCurrentCartInfo(currentCartInfo) {
        this.save(APP_DATA_KEYS.CURRENT_CART_INFO, currentCartInfo);
    }

    getCurrentCartInfo() {
        return this.get(APP_DATA_KEYS.CURRENT_CART_INFO);
    }

    removeCurrentCartInfo(key) {
        this.remove(key);
    }

    saveCartList(cartList) {
        this.save(APP_DATA_KEYS.CART_LIST, cartList);
    }

    getCartList() {
        return this.get(APP_DATA_KEYS.CART_LIST);
    }

    removeCartList(key) {
        this.remove(key);
    }

    startAttachEmptyCartRedirection() {
        this.save(APP_DATA_KEYS.ATTACH_EMPTY_CART_REDIRECTED_FLOW, true);
    }

    isAttachEmptyCartRedirectionInProgress() {
        return this.get(APP_DATA_KEYS.ATTACH_EMPTY_CART_REDIRECTED_FLOW);
    }

    completeAttachEmptyCartRedirection() {
        this.save(APP_DATA_KEYS.ATTACH_EMPTY_CART_REDIRECTED_FLOW, false);
    }
}

const AppDataStore = new AppData();

export default AppDataStore;