import React, {useContext} from 'react';
import Column from "@amzn/meridian/column";
import {PrepareContainerContext} from "../../context/PrepareContainerContext";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import ScanQrCodeView from "../../../../scan-qr-code-view/ScanQrCodeView";
import {AR_VIEW_STRINGS} from "../../../../../constants/Strings";
import {attachContainerToDestination} from "../../action/PrepareContainerAction";
import ScanView from "../../../../scan-view/ScanView";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";
import {ScanTypes} from "../../../../player/PlayerConstant";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import Loading from "../../../../loading/Loading";

const ScanContainerLocationView = () => {

    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {configViewModel} = useContext(ConfigContext)
    const {statePlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        const request = {
            workflow: configViewModel.workflow,
            containerScannableId: prepareContainerModel.activeCartId,
            destinationScannableId: input,
            containerScannableType: CONTAINER_TYPE.CART,
            scope: configViewModel.scope
        }
        prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
        statePlayer.scanType = ScanTypes.PREP_CART_LOCATION_SCAN
        attachContainerToDestination(prepareContainerDispatch, request)
    }

    const init = {
        primaryTitle: AR_VIEW_STRINGS.confirm_cart_location,
        secondaryTitle: AR_VIEW_STRINGS.scan_location
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            {prepareContainerModel.loading && <Loading/>}
            <ScanQrCodeView {...init}/>
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default ScanContainerLocationView
