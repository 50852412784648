import React, {useContext, useEffect} from 'react';
import {PrepareContainerContext} from "../context/PrepareContainerContext";
import {getNavTitleForWorkflow, VIEW} from "../../../workflow/Workflow";
import ScanBagView from "./scan-bag/ScanBagView";
import ScanBagLocationView from "./scan-bag-location/ScanBagLocationView";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {isNonNull} from "../../../utils/Util";
import {FormattedMessage} from "react-intl";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {APP_NAVIGATION_STRINGS, NOTIFICATION_STRING} from "../../../../constants/Strings";
import {ConfigContext} from "../../../config/context/ConfigContext";
import ScanPalletLocationView from "./scan-pallet-location/ScanPalletLocationView";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {getContainerRoutingPath, ROUTING} from "../../../../constants/Routing";
import {setGlobalContext} from "../../../config/action/ConfigAction";
import {NOTIFICATION_TYPE} from "../../../../constants/NotificationType";
import ScanCartView from "./scan-cart/ScanCartView";
import ScanCartLocationView from "./scan-cart-location/ScanCartLocationView";
import {interceptBackButton, interceptBackButtonStop} from "../../../bridge/BackButton";
import ScrubNonEmptyBagConfirmView from "./scrub-non-empty-bag/ScrubNonEmptyBagConfirmView";
import ScrubNonEmptyBagView from "./scrub-non-empty-bag/ScrubNonEmptyBagView";
import {RESPONSE} from "../../../../constants/ResponseCodes";
import ReplaceNonEmptyBagView from "./scrub-non-empty-bag/ReplaceNonEmptyBagView";
import ScrubNonEmptyLocationView from "./scrub-non-empty-location/ScrubNonEmptyLocationView";
import ScrubNonEmptyLocationConfirmView from "./scrub-non-empty-location/ScrubNonEmptyLocationConfirmView";
import {openBagAfterScrub} from "../action/PrepareContainerAction";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {ScanTypes} from "../../../player/PlayerConstant";
import {ApplicationActions} from "../../../player/action/ApplicationActions";
import {STOW_AREA_OPERATION} from "../../../../constants/StowAreaOperation";

const PrepareContainerView = (props) => {

    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {notificationActions: {setNotification, clearNotification}} = useContext(NotificationContext);
    const {navActions: {setNavTitle, openBackDrop, initContext, clearContext}} = useContext(AppNavigationContext)
    const {configViewModel, configViewDispatch} = useContext(ConfigContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    useEffect(() => {
        if (isNonNull(prepareContainerModel.notification)) {
            setNotification(prepareContainerModel.notification)
        }
        if (prepareContainerModel.view === VIEW.SCAN_CART_LOCATION) {
            clearNotification()
        }

        if (isNonNull(prepareContainerModel.nextRoute)) {
            interceptBackButtonStop()
            if (prepareContainerModel.nextRoute === getContainerRoutingPath(ROUTING.REPLACE_CONTAINER, CONTAINER_TYPE.BAG)) {
                setGlobalContext(configViewDispatch, {
                    notification: {
                        type: NOTIFICATION_TYPE.SUCCESS,
                        message: NOTIFICATION_STRING.ALERT_SUCCESS_BAG_MOVED_TO_PS,
                        values: {displayBagId: prepareContainerModel.displayBagId}
                    }
                })
            }
            props.history.push(prepareContainerModel.nextRoute)
        }

        if (isNonNull(prepareContainerModel.responseCode) && !(prepareContainerModel.loading)) {
            switch (statePlayer.scanType) {
                case ScanTypes.PREP_BAG_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_SCAN, data: {errorResponse: prepareContainerModel.responseCode,
                            operation: STOW_AREA_OPERATION.PREPARE_CONTAINER, type: CONTAINER_TYPE.BAG, location: configViewModel.locationLabel}});
                    break;
                case ScanTypes.PREP_BAG_LOCATION_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_LOCATION_SCAN, data: {errorResponse: prepareContainerModel.responseCode,
                            type: CONTAINER_TYPE.BAG, operation: STOW_AREA_OPERATION.PREPARE_CONTAINER, workflow: prepareContainerModel.workflow}});
                    break;
                case ScanTypes.PREP_PALLET_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_SCAN, data: {errorResponse: prepareContainerModel.responseCode,
                            operation: STOW_AREA_OPERATION.PREPARE_CONTAINER, type: CONTAINER_TYPE.PALLET}});
                    break;
                case ScanTypes.PREP_CART_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_SCAN, data: {errorResponse: prepareContainerModel.responseCode,
                            operation: STOW_AREA_OPERATION.PREPARE_CONTAINER, type: CONTAINER_TYPE.CART}});
                    break;
                case ScanTypes.PREP_CART_LOCATION_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_LOCATION_SCAN, data: {errorResponse: prepareContainerModel.responseCode,
                            type: CONTAINER_TYPE.CART, operation: STOW_AREA_OPERATION.PREPARE_CONTAINER, workflow: prepareContainerModel.workflow}});
                    break;
                default:
                    break;
            }
        }
    }, [prepareContainerModel]);

    useEffect(() => {
        const onBackPressed = () => {
            openBackDrop({
                secondaryButtonOnClick: () => props.history.push(ROUTING.ENTRY)
            });
        }
        initContext({onBackPressed})
        interceptBackButton('prepare container view')
        return clearContext
    }, []);

    const renderView = () => {
        if (props.containerScannableType === CONTAINER_TYPE.PALLET) {
            return <ScanPalletLocationView history={props.history}/>
        } else if (props.containerScannableType === CONTAINER_TYPE.CART) {
            if (prepareContainerModel.view === VIEW.SCAN_CART_LOCATION) {
                return <ScanCartLocationView/>
            } else {
                return <ScanCartView history={props.history}/>
            }
        }

        switch (prepareContainerModel.view) {
            case VIEW.SCAN_BAG_LOCATION:
                return <ScanBagLocationView />
            case VIEW.SCRUB_NON_EMPTY_BAG:
                return <ScrubNonEmptyBagView history={props.history}/>
            case VIEW.SCRUB_NON_EMPTY_BAG_CONFIRM:
                return <ScrubNonEmptyBagConfirmView history={props.history}/>
            case VIEW.REPLACE_NON_EMPTY_BAG:
                return <ReplaceNonEmptyBagView history={props.history}/>
            case VIEW.SCRUB_NON_EMPTY_LOCATION:
                return <ScrubNonEmptyLocationView history={props.history}/>
            case VIEW.SCRUB_NON_EMPTY_LOCATION_CONFIRM:
                if (prepareContainerModel.responseCode === RESPONSE.SUCCESS){
                    prepareContainerModel.responseCode = null;
                    return openBagAfterScrub(prepareContainerDispatch, prepareContainerModel.bagScannableId, prepareContainerModel.destinationScannableId,
                        configViewModel.scope, configViewModel.workflow);
                } else {
                    return <ScrubNonEmptyLocationConfirmView history={props.history}/>
                }
            default:
                return <ScanBagView history={props.history}/>
        }
    }

    useEffect(() => {
        setNavTitle(getNavTitleForWorkflow(configViewModel.workflow,
            <FormattedMessage id={APP_NAVIGATION_STRINGS.prepare_container}/>));
    }, [])

    return (
        <div>
            {renderView()}
        </div>
    )
}

export default PrepareContainerView
