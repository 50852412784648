import React, {useContext, useEffect} from 'react';
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import QrCode from "../../../../../images/ic_qr_code.png";
import {AR_VIEW_STRINGS} from "../../../../../constants/Strings";
import {injectIntl} from "react-intl";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import ScanView from "../../../../scan-view/ScanView";
import Column from "@amzn/meridian/column";
import {clearContext, validateContainerBeforeAttach} from "../../action/PrepareContainerAction";
import {PrepareContainerContext} from "../../context/PrepareContainerContext";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ScanTypes} from "../../../../player/PlayerConstant";
import {getHelpData} from "./data/ScanBagCartViewData";
import Loading from "../../../../loading/Loading";

const ScanCartView = (props) => {

    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {configViewModel} = useContext(ConfigContext)
    const {navActions: {setHelpOption, closeHelpOption}} = useContext(AppNavigationContext);
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        const request = {
            workflow: configViewModel.workflow,
            containerScannableId: input,
            containerScannableType: CONTAINER_TYPE.CART,
            scope: configViewModel.scope
        }
        prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
        statePlayer.scanType = ScanTypes.PREP_CART_SCAN
        validateContainerBeforeAttach(prepareContainerDispatch, request)
    }

    const init = {
        primaryTitle: {
            title: props.intl.formatMessage({id: AR_VIEW_STRINGS.scan_empty_cart}),
            type: "Bold",
            size: "Large"
        },
        image: QrCode
    }

    useEffect(() => {
        clearContext(prepareContainerDispatch);
        setHelpOption(true, getHelpData(props, dispatchPlayer, closeHelpOption));
        return () => setHelpOption(false, {});
    }, []);

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            {prepareContainerModel.loading && <Loading/>}
            <ScanWithCodeView {...init}/>
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(ScanCartView)
