import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import closeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import Column from "@amzn/meridian/column";
import React, {useContext} from "react";
import {COMMON_STRINGS} from "../../constants/Strings";
import {AppNavigationContext} from "./context/AppNavigationContext";
import {injectIntl} from "react-intl";
import {ROUTING} from "../../constants/Routing";
import {PlayerContext} from "../player/context/PlayerContext";
import {ApplicationActions} from "../player/action/ApplicationActions";
import AppDataStore from "../app-data";
import {RedirectHandler} from "@amzn/dolphin-web-framework";

const AppBackdrop = (props) => {

    const {navActions} = useContext(AppNavigationContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const handleClose = () => {
        navActions.closeBackDrop();
        dispatchPlayer({type: ApplicationActions.BACK_OPTION_SELECTED, data: {operation: COMMON_STRINGS.CANCEL}});
    };

    const handleContinueStowing = () => {
        navActions.closeBackDrop();
        dispatchPlayer({type: ApplicationActions.BACK_OPTION_SELECTED, data: {operation: COMMON_STRINGS.CONTINUE_STOWING}});
    };

    const handleEndStowing = () => {
        dispatchPlayer({type: ApplicationActions.BACK_OPTION_SELECTED, data: {operation: COMMON_STRINGS.END_STOWING}});
        AppDataStore.completeHotSwap();
        if (AppDataStore.isAttachEmptyCartRedirectionInProgress()) {
            AppDataStore.completeAttachEmptyCartRedirection();
            RedirectHandler.callback({});
        } else {
            props.history.push(ROUTING.ENTRY);
        }
    };

    return <Column className={"backdrop"} spacingInset={"small"}>
        <Row alignmentHorizontal="right" spacing={"none"}>
            <Button type="icon" onClick={handleClose}>
                <Text color={"inverted"}><Icon tokens={closeTokens}/></Text>
            </Button>
        </Row>
        <Row spacingInset={"medium"}>
            <Text type="h400"
                  color="inverted">{props.intl.formatMessage({id: COMMON_STRINGS.BACK_BUTTON_PRESSED})}</Text>
        </Row>
        <Column spacingInset={"medium"}>
            <Text type="b200"
                  color="inverted">{props.intl.formatMessage({id: COMMON_STRINGS.BACK_BUTTON_NOTIFICATION})}</Text>
        </Column>
        <Column className={"footer"} spacingInset={"medium"}>
            <Button
                onClick={handleContinueStowing}>{props.intl.formatMessage({id: COMMON_STRINGS.CONTINUE_STOWING})}</Button>
            <Button type={"secondary"}
                    onClick={handleEndStowing}>{props.intl.formatMessage({id: COMMON_STRINGS.END_STOWING})}</Button>
        </Column>
    </Column>;
}

export default injectIntl(AppBackdrop)
