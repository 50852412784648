import React, {useContext, useEffect} from 'react';
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import {injectIntl} from "react-intl";
import {NOTIFICATION_STRING, REPLACE_CONTAINER_VIEW_STRINGS} from "../../../../../constants/Strings";
import {getFormattedString, getNotificationFormat} from "../../../../utils/Util";
import {NotificationContext} from "../../../../notification/context/NotificationContext";
import {interceptBackButton, interceptBackButtonStop} from "../../../../bridge/BackButton";
import HeadingView, {HEADING_TYPE} from "../../../../heading-view/HeadingView";
import {PrepareContainerContext} from "../../../prepare-container/context/PrepareContainerContext";
import {NOTIFICATION_TYPE} from "../../../../../constants/NotificationType";
import {CHANGE_ACTIVE_VIEW} from "../../../prepare-container/action/PrepareContainerAction";
import {VIEW} from "../../../../workflow/Workflow";
import Loading from "../../../../loading/Loading";

const ReplaceBagView = (props) => {

    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)

    const onClick = () => {
        const notification = getNotificationFormat(NOTIFICATION_TYPE.SUCCESS, NOTIFICATION_STRING.ALERT_SUCCESS_BAG_MOVED_TO_PS,
                {displayBagId: prepareContainerModel.activeBagId})
        setNotification(notification)
        prepareContainerDispatch({
            type: CHANGE_ACTIVE_VIEW,
            data: {
                view: VIEW.BAG_SCAN_VIEW
            }
        })

    }

    useEffect(() => {
        interceptBackButton('replace container view')
        return interceptBackButtonStop
    }, [])

    const headerData = {
        primaryTitle: REPLACE_CONTAINER_VIEW_STRINGS.replace_bag,
        secondaryTitle: REPLACE_CONTAINER_VIEW_STRINGS.move_bag_to_PS
    }
    return (
        <div>
            <Column height="100%" spacingInset="medium" heights="fill">
                {prepareContainerModel.loading && <Loading/>}
                <Column alignmentHorizontal="center">
                    <HeadingView {...headerData} type={HEADING_TYPE.PRIMARY}/>
                </Column>
                <Column className={"footer"} spacingInset={"small"}>
                    <Button onClick={onClick} size="large" type="primary">
                        {getFormattedString(props, REPLACE_CONTAINER_VIEW_STRINGS.confirm_bag_moved)}
                    </Button>
                </Column>
            </Column>
        </div>
    )
}

export default injectIntl(ReplaceBagView)
