import React, {useContext, useEffect} from 'react';
import {ScrubContainerContext} from "../../context/ScrubContainerContext";
import {SCRUB_CONTAINER_STRINGS} from "../../../../constants/Strings";
import {FormattedMessage} from "react-intl";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {isNonNull} from "../../../utils/Util";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {VIEW} from "../../../workflow/Workflow";
import ScanContainerView from "./ScanContainerView";
import ScrubContainerConfirmationView from "./ScrubContainerConfirmationView";
import {ApplicationActions} from "../../../player/action/ApplicationActions";
import {ScanTypes} from "../../../player/PlayerConstant";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {ROUTING} from "../../../../constants/Routing";

const ScrubContainerView = (props) => {
    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {scrubContainerViewModel} = useContext(ScrubContainerContext);
    const {navActions: {setNavTitle}} = useContext(AppNavigationContext);
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    useEffect(() => {
        if (isNonNull(scrubContainerViewModel.notification)) {
            setNotification(scrubContainerViewModel.notification)
        }
        if(scrubContainerViewModel.responseCode !== "" && statePlayer.scanType === ScanTypes.SCRUB_CONTAINER) {
            dispatchPlayer({type: ApplicationActions.SCRUB_CONTAINER_OR_LOCATION, data: {errorResponse: scrubContainerViewModel.responseCode, type: SCRUB_CONTAINER_STRINGS.scrub_container_title}});
        }
    }, [scrubContainerViewModel]);

    useEffect(() => {
        setNavTitle(<FormattedMessage id = {SCRUB_CONTAINER_STRINGS.scrub_container_title}/>);

    }, []);

    const renderView = () => {
        if (VIEW.SCRUB_CONTAINER === scrubContainerViewModel.view || scrubContainerViewModel.view === '') {
            scrubContainerViewModel.view = VIEW.SCRUB_CONTAINER;
            return <ScanContainerView history = {props.history}/>
        } else if(VIEW.SCRUB_LOCATION === scrubContainerViewModel.view) {
            scrubContainerViewModel.view = VIEW.SCRUB_LOCATION;
            props.history.push(ROUTING.SCRUB_LOCATION);
        } if (VIEW.SCRUB_CONTAINER_CONFIRM === scrubContainerViewModel.view) {
            scrubContainerViewModel.view = VIEW.SCRUB_CONTAINER_CONFIRM;
            return <ScrubContainerConfirmationView history = {props.history}/>
        }
    }

    return (
        <div>
            {renderView()}

        </div>
    )
}

export default ScrubContainerView
